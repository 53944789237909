.text-field {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 55px;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    justify-content: space-between;
    flex-direction: row;
  }

  &__label {
    position: absolute;
    left: 0;
    top: unset;
    transition: transform 0.1s ease-in-out;
    color: $colour-heathered-grey;
    font-size: 14px;
    font-weight: 400;

    &_active {
      transform: translateY(-10px);
    }
  }

  &__input {
    height: 100%;
    width: 100%;
    font-size: 15px;
    border: 0;
    outline: none;
    padding: 28px 0 9px;
    font-weight: 400;
    color: $colour-cocoa-brown;
    background-color: transparent;
  }
}
